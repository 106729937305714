import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { FaUser } from "react-icons/fa";
import BackButton from "./BackButton";

const DoctorDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fullBio, setFullBio] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchDoctorDetails = async () => {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/doctor_details`,
        {
          doctor_id: id,
        }
      );
      setData(res.data);
      setLoading(false);
    };
    fetchDoctorDetails();
  }, []);

  return (
    <div className="doctor-profile-wrapper">
      <BackButton />
      <header className="head">
        <h2 className="font-bold">Doctor Profile</h2>
      </header>
      {loading ? (
        <BeatLoader
          className="flex justify-center items-center"
          color="#f1f1f1"
          loading={loading}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div className="doctor-profile-box">
          <div className="profile">
            {data.doctor_img ? (
              <img
                src={data.doctor_img}
                className="p-1 rounded-full bg-slate-600 sm:h-52 h-36"
                alt={data.doctor_name}
                loading="lazy"
              />
            ) : (
              <FaUser size={180} className="p-1 rounded-full bg-slate-300 " />
            )}

            <div className="basic-details">
              <h2 className="text-2xl font-semibold tracking-wide">
                {data.doctor_name}
              </h2>
              <h5 className="text-sm text-primary">
                Contact- {data.doctor_phone}
              </h5>
              <h5 className="text-xs text-primary">
                Exp- {data.doctor_exp} years
              </h5>
              <div className="address-detail max-w-[87%] text-secondary py-2">
                {data.doctor_address}
              </div>
              <button
                type="submit"
                className="book-appointment-btn"
                onClick={() => navigate("/home/schedule-appointment")}
              >
                Book Appointment
              </button>
            </div>
          </div>
          <div className="other-details">
            <h3 className="detail">
              <span className="text-secondary">Bio-</span>{" "}
              <span>
                {/* {data.doctor_bio} */}
                {fullBio ? data.doctor_bio : data.doctor_bio.substring(0, 300)}
              </span>
              {!fullBio ? (
                <span
                  className="text-xs text-slate-500 cursor-pointer"
                  onClick={() => setFullBio(!fullBio)}
                >
                  ...View More
                </span>
              ) : (
                <span
                  className="text-xs text-slate-500 cursor-pointer"
                  onClick={() => setFullBio(!fullBio)}
                >
                  ...View Less
                </span>
              )}
            </h3>{" "}
            <h3 className="detail">
              <span className="text-secondary">
                Education & Qualifications-
              </span>{" "}
              {data.doctor_medical_school}
            </h3>
            <h3 className="detail">
              <span className="text-secondary">Practice Areas-</span>{" "}
              {data.doctor_practices}
            </h3>
            <h3 className="detail">
              <span className="text-secondary">Consultation Fees-</span>{" "}
              {data.doctor_fees}
            </h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default DoctorDetails;
