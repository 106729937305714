import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import PropTypes from "prop-types";
import axios from "axios";

import { BeatLoader } from "react-spinners";
import { HiSpeakerWave } from "react-icons/hi2";
import { AiFillCopy } from "react-icons/ai";
import toast from "react-hot-toast";
import Markdown from "markdown-to-jsx";
import logo from "../assets/logo.svg";
import DoctorProfileCard from "./DoctorProfileCard";

const History = ({ chat, loading: chatsLoading }) => {
  const [showRecommend, setShowRecommend] = useState(false);
  const [recommends, setRecommends] = useState([]);

  const [loading, setLoading] = useState(false);

  const [speechSpeaking, setSpeechSpeaking] = useState(false);

  useEffect(
    () => () => {
      window.speechSynthesis.cancel();
      setSpeechSpeaking(false);
    },
    []
  );

  const handleSpeaker = (text) => {
    if (speechSpeaking) {
      window.speechSynthesis.cancel();
      setSpeechSpeaking(false);
    } else {
      const msg = new SpeechSynthesisUtterance(text);
      msg.onend = () => {
        setSpeechSpeaking(false);
      };
      window.speechSynthesis.speak(msg);
      setSpeechSpeaking(true);
    }
  };

  const handleCopy = (message) => {
    let text;
    if (message) {
      text = message;
    }
    navigator.clipboard.writeText(text);
    toast.success("Text Copied!");
  };

  const handleRecommendDoctor = (userMessage) => {
    async function fetchRecommendedDoctor(userQuery) {
      setLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/recommend_doctor`,
        {
          query: userQuery,
        }
      );
      setRecommends(data);
      setLoading(false);
      setShowRecommend(true);
    }
    fetchRecommendedDoctor(userMessage);
  };

  return (
    <div className="chat-history">
      {chat.user[0].user_type === "USER" ? (
        <div className="user-history-chat">
          <FaUser size={32} className="p-1 rounded-full bg-slate-400" />
          <div className="query">{chat.message}</div>
        </div>
      ) : (
        <div className="bot-chathistory-wrapper">
          <div className="bot-history-chat">
            <img
              className="w-8 object-contain rounded-full bg-slate-400"
              src={logo}
              alt="bot"
            />
            {chat.message && (
              <div className="response">
                <Markdown>{chat.message}</Markdown>
              </div>
            )}

            <div className="options flex flex-col md:gap-4 gap-3">
              {/* <img className="icons" src={like} alt="like-icons" />
      <img className="icons" src={dislike} alt="dislike-icons" /> */}
              <HiSpeakerWave
                className={`cursor-pointer ${
                  speechSpeaking && "speaker-animation"
                }`}
                color="#917EB1"
                size={18}
                onClick={() => handleSpeaker(chat.message)}
              />
              <AiFillCopy
                className="cursor-pointer"
                color="#917EB1"
                size={18}
                onClick={() => handleCopy(chat.message)}
              />
            </div>
          </div>
          {!chatsLoading && !showRecommend ? (
            <button
              type="button"
              className="recommend-btn text-sm py-1 my-4 max-w-max"
              onClick={() => handleRecommendDoctor(chat.message)}
            >
              {loading ? (
                <BeatLoader
                  className="flex justify-center items-center"
                  color="#917EB1"
                  loading={loading}
                  size={8}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                "Recommend Doctors"
              )}
            </button>
          ) : (
            <div className="doctor-card-wrapper">
              {recommends.map((doctor) => (
                <DoctorProfileCard key={doctor.doctor_id} {...doctor} />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
History.propTypes = {
  chat: PropTypes.object,
  loading: PropTypes.bool,
};
History.defaultProps = {
  chat: "",
  loading: "",
};

export default History;
