import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InlineWidget } from "react-calendly";
import { IoCloseCircle } from "react-icons/io5";
import BackButton from "./BackButton";

const Calendly = () => {
  const { authSlice } = useSelector((state) => state);

  const email = authSlice.user.userName;
  const name = authSlice.user.userName.split("@")[0];
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navigate = useNavigate();
  return (
    <div className="appointment-book-wrapper relative">
      <BackButton />
      <header className="head">
        <h2 className="font-bold">Book Appointment</h2>

        <IoCloseCircle
          className="z-40 cursor-pointer absolute md:right-10 right-4 md:top-12 p-1 rounded-full hover:scale-105"
          color="#917EB1"
          size={40}
          onClick={() => navigate("/home")}
        />
      </header>

      <InlineWidget
        url={`${process.env.REACT_APP_CALENDLY_API_URL}/consultation`}
        className="calendly-component -top-10"
        styles={{
          height: "100%",
          position: "relative",
          top: isMobile ? "0" : "-40px",
        }}
        // pageSettings={{
        //   backgroundColor: "#1C1D23",
        //   primaryColor: "#E9FE53",
        //   textColor: "#ffffff",
        // }}
        prefill={{
          email,
          name,
        }}
      />
    </div>
  );
};
export default Calendly;
