import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import axios from "axios";
import { FaSignInAlt } from "react-icons/fa";
import { MdEmail, MdPassword } from "react-icons/md";
import { toast } from "react-hot-toast";
import { landingSignupIllustration, logo } from "../assets";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const SignUp = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const signUp = async ({ email, password }) => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_API_URL}/api/v1/user/signup`,
          {
            email,
            password,
          }
        );

        if (response.statusText === "OK") {
          setLoading(false);
          toast.success("Sign-up Successful. Please Sign-in Now!!!");
          navigate("/signin");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 409) {
            toast.error(`${error.response.data.errorData.message}`);
          } else if (error.response.status === 401) {
            toast.error(`${error.response.data.errorData.message}`);
          } else {
            toast.error(`${error.response.data.errorData.message}`);
          }
        } else if (error.request) {
          toast.error(`No Response received`);
        } else {
          toast.error("Error:", error.message);
        }
        setLoading(false);
      }
    };

    signUp(data);
  };

  return (
    <div className="signup-wrapper">
      <div
        className="flex items-center gap-2 mb-2 cursor-pointer lg:hidden absolute top-3"
        onClick={() => navigate("/home")}
      >
        <img
          src={logo}
          className="md:w-10 w-6 mx-1 object-contain"
          alt="logo"
        />
        <h2 className="font-bold md:text-2xl text-xl text-primary">
          mediq.expert
        </h2>
      </div>
      <div className="form-wrapper">
        <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="flex gap-2 justify-center items-center text-3xl text-bold text-primary my-3">
            <FaSignInAlt size={32} color="#917EB1" />
            Sign Up
          </h2>
          <div className="signup-input-wrapper">
            <MdEmail className="signup-icon" color="#917EB1" size={18} />
            <input
              className={`signup-input ${errors.email && "invalid-input"}`}
              type="email"
              {...register("email")}
              placeholder="Enter email..."
              autoComplete="off"
            />
          </div>
          {errors.email && (
            <span className="error-message">{errors.email.message}</span>
          )}

          <div className="signup-input-wrapper">
            <MdPassword className="signup-icon" color="#917EB1" size={18} />
            <input
              className={`signup-input ${errors.password && "invalid-input"}`}
              type="password"
              {...register("password")}
              placeholder="Enter Password..."
            />
          </div>
          {errors.password && (
            <span className="error-message">{errors.password.message}</span>
          )}

          <div className="signup-input-wrapper">
            <MdPassword className="signup-icon" color="#917EB1" size={18} />
            <input
              className={`signup-input ${
                errors.confirmPassword && "invalid-input"
              }`}
              type="password"
              {...register("confirmPassword")}
              placeholder="Confirm Password..."
            />
          </div>
          {errors.confirmPassword && (
            <span className="error-message">
              {errors.confirmPassword.message}
            </span>
          )}
          <button className="signup-btn" type="submit">
            {loading ? "Signing Up..." : "Sign Up"}
          </button>
          <span className="signin-info">
            <p>
              Already have an account?{" "}
              <Link className="link" to="/signin">
                Signin here!
              </Link>
            </p>
          </span>
        </form>
        <div className="signup-img">
          <img
            src={landingSignupIllustration}
            alt="landingSignupIllustration.png"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
