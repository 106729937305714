import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";

import { useNavigate, useParams } from "react-router-dom";
import { IoSend } from "react-icons/io5";
import axios from "axios";
import toast from "react-hot-toast";
import BackButton from "./BackButton";

import { addChatToHistory, appendWithHistory } from "../features/chatSlice";
import History from "./History";
import { logOut } from "../features/authSlice";

const HistoryDashboard = () => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const store = useSelector((state) => state);
  const { chatSlice, authSlice } = store;
  const dispatch = useDispatch();

  const { chatHistory } = chatSlice;

  const socketRef = useRef(null);
  const historyBoxRef = useRef();

  const navigate = useNavigate();

  const botIdRef = useRef(null);
  const { token } = authSlice.user;
  const userId = authSlice.user.id;
  const { sessionId } = useParams();

  const getSession = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const page = 1;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/session?page=${page}`,
        { headers }
      );

      if (response.statusText === "OK") {
        const { data } = response.data;
        dispatch(addChatToHistory(data));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          dispatch(logOut());
          navigate("/");
          toast.error(`${error.response.data.errorData.message}`);
        } else if (error.response.status === 401) {
          dispatch(logOut());
          navigate("/");
          toast.error(`${error.response.data.errorData.message}`);
        } else {
          dispatch(logOut());
          navigate("/");
          toast.error(`${error.response.data.errorData.message}`);
        }
      } else if (error.request) {
        dispatch(logOut());
        navigate("/");
        toast.error(`No Response received`);
      } else {
        dispatch(logOut());
        navigate("/");
        toast.error(`No Response received`);
      }
    }
  };

  useEffect(() => {
    socketRef.current = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_BASE_API_URL}/ws/${sessionId}/${userId}`
    );

    socketRef.current.onopen = () => {};

    socketRef.current.onmessage = (event) => {
      const message = event.data;

      const msgObj = JSON.parse(message);

      if (msgObj.type === "start") {
        setLoading(true);
        botIdRef.current = uuidv4();
      }

      if (msgObj.type === "stream") {
        if (msgObj.sender_type === "USER") {
          dispatch(
            appendWithHistory({
              _id: uuidv4(),
              message: msgObj.message,
              user: [
                {
                  user_type: "USER",
                },
              ],
            })
          );
        }
        if (msgObj.sender_type === "BOT") {
          dispatch(
            appendWithHistory({
              _id: botIdRef.current,
              message: msgObj.message,
              user: [
                {
                  user_type: "BOT",
                },
              ],
            })
          );
        }
        setQuery("");
      }

      if (msgObj.type === "end") {
        setLoading(false);
        setQuery("");
      }
    };

    socketRef.current.onclose = () => {};

    return () => {
      if (socketRef.readyState === 1) {
        socketRef.close();
      }
    };
  }, [sessionId]);

  useEffect(() => {
    if (!loading) getSession();
  }, [loading]);

  useEffect(() => {
    if (historyBoxRef.current) {
      historyBoxRef.current.scrollTop = historyBoxRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleSubmit = (e) => {
    if (query.length < 2) {
      return;
    }
    e.preventDefault();

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ message: query }));
    }

    setLoading(false);
  };

  return (
    <div className="history-wrapper">
      <BackButton />

      <div className="chat-box scroll-smooth !pb-4" ref={historyBoxRef}>
        {chatHistory.map((chat) => (
          <History key={chat._id} chat={chat} />
        ))}
      </div>

      <form className="chat-form" onSubmit={handleSubmit}>
        <input
          className="chat-input"
          name="chatInput"
          value={query}
          type="text"
          onChange={(e) => setQuery(e.target.value)}
          disabled={loading}
          autoComplete="off"
          placeholder="Write a prompt..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit(e);
            }
          }}
        />

        {loading ? (
          <div className="loader">
            <BeatLoader
              color="#f1f1f1"
              loading={loading}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div className="inp-btn">
            <IoSend
              className="bg-primary p-1 cursor-pointer"
              color="black"
              size={25}
              onClick={handleSubmit}
            />
          </div>
        )}
      </form>
    </div>
  );
};

export default HistoryDashboard;
