/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { FaSearch } from "react-icons/fa";
import TableList from "./TableList";
import BackButton from "./BackButton";

const DoctorList = () => {
  const [selectedBtn, setSelectedbtn] = useState("all-doctors");
  const [doctorList, setDoctorList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [loading, setLoading] = useState(false);

  const fetchDoctors = async () => {
    setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/list_doctors`
    );

    setLoading(false);
    setDoctorList(res.data);
  };
  useEffect(() => {
    fetchDoctors();
  }, []);

  useEffect(() => {
    if (searchQuery === "") {
      fetchDoctors();
      return;
    }

    const searchResult = doctorList.filter(
      (doctor) =>
        doctor.doctor_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        doctor.doctor_role.toLowerCase().includes(searchQuery.toLowerCase()) ||
        doctor.doctor_state.toLowerCase().includes(searchQuery.toLowerCase()) ||
        doctor.doctor_website.toLowerCase().includes(searchQuery.toLowerCase())
    );
    console.log(searchResult);

    setDoctorList(searchResult);
  }, [searchQuery]);

  const handleSelectDoctor = (e) => {
    setSelectedbtn(e.target.id);
  };

  const navigate = useNavigate();

  const handleSearchList = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="doctorlist-wrapper">
      <BackButton />
      <div className="doctors-input-wrapper md:mt-0 mt-4">
        <FaSearch className="search-icon" />
        <input
          className="doctor-search-input px-1"
          type="text"
          alt="search-doctors"
          placeholder="Search"
          onChange={handleSearchList}
        />
      </div>
      <div className="selectDoctor-btns">
        <button
          id="all-doctors"
          type="button"
          className={`${
            selectedBtn === "all-doctors" && "border-b-[1px]  border-primary"
          } selectDoctor-btn`}
          onClick={handleSelectDoctor}
        >
          All Doctors ({doctorList ? doctorList.length : 0})
        </button>
      </div>
      {selectedBtn === "all-doctors" ? (
        <div className="flex-grow overflow-auto">
          <div className="table-wrapper">
            {loading ? (
              <BeatLoader
                className="flex justify-center items-center"
                color="#917EB1"
                loading={loading}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <>
                {doctorList.length > 0 ? (
                  doctorList.map((doctor, index) => (
                    <TableList
                      key={doctor.doctor_id}
                      {...doctor}
                      index={index}
                      navigate={navigate}
                    />
                  ))
                ) : (
                  <h2 className="text-center text-xl fw-bold text-secondary">
                    No Data Found
                  </h2>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="table-wrapper">
          <TableList />
        </div>
      )}
    </div>
  );
};

export default DoctorList;
