import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdLogout, MdSpaceDashboard } from "react-icons/md";
import { FaUser, FaUsers } from "react-icons/fa";
import RecentChats from "./RecentChats";
import { clearChat } from "../features/chatSlice";
import { logo } from "../assets";
import { logOut, storeSession } from "../features/authSlice";

const Sidebar = () => {
  const [dropUp, setDropUp] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const store = useSelector((state) => state);

  const { chatSlice, authSlice } = store;

  const { recentChat } = chatSlice;
  const { user } = authSlice;

  const showDropUp = () => {
    setDropUp(!dropUp);
  };

  const createSession = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    };

    const session_name = "New Chat";

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/session?session_name=${session_name}`,
        {},
        { headers }
      );

      if (response.statusText === "OK") {
        const { session_id } = response.data.data;
        dispatch(storeSession(session_id));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          toast.error(`${error.response.data.errorData.message}`);
        } else if (error.response.status === 401) {
          toast.error(`${error.response.data.errorData.message}`);
        } else {
          toast.error(`${error.response.data.errorData.message}`);
        }
      } else if (error.request) {
        toast.error(`No Response received`);
      } else {
        toast.error(`No Response received`);
      }
    }
  };
  const handleNewChat = () => {
    navigate("/home");
    dispatch(clearChat());
    createSession();
  };

  const handleLogout = () => {
    dispatch(logOut());
    toast.success("Signed-out Successfully!!!");

    navigate("/");
  };

  return (
    <header className="sidebar-wrapper">
      <div className="sidebar-top">
        <div className="sidebar-upper flxCol s-p !pb-4 border-b-[1px] border-light">
          <div
            className="flex items-center gap-2 mb-2 cursor-pointer"
            onClick={() => navigate("/home")}
          >
            <img
              src={logo}
              className="md:w-10 w-6 mx-1 object-contain"
              alt="logo"
            />
            <h2 className="font-bold md:text-2xl text-xl text-primary">
              mediq.expert
            </h2>
          </div>
          <ul>
            <li className="nav-item" onClick={handleNewChat}>
              <MdSpaceDashboard size={20} color="#917EB1" />

              <h3 className="nav-text">Dashboard</h3>
            </li>
            <li className="nav-item" onClick={() => navigate("/home/doctors")}>
              <FaUsers size={20} color="#917EB1" />

              <h3 className="nav-text">Doctor Directory</h3>
            </li>
            {/* <li
              className="nav-item"
              onClick={() => navigate("/home/appointment")}
            >
              <img className="icons sidebar-icons" src={calendar} alt="icon" />

              <h3 className="nav-text">Appointments</h3>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="flex-grow overflow-auto">
        <div className="sidebar-mid flxCol s-p ">
          <span className="flex justify-between items-center cursor-pointer">
            <h2 className=" font-semibold text-primary">Recent Chats</h2>
            {/* <img className="icons sidebar-icons" src={arrowDown} alt="icon" /> */}
          </span>
          {recentChat?.length > 0 ? (
            <RecentChats />
          ) : (
            <h2 className=" font-semibold text-secondary text-center my-2">
              No recent chats!
            </h2>
          )}
        </div>
      </div>
      <div className="sidebar-lower flxCol s-p !pt-4 border-t-[1px] border-light">
        <button type="button" className="new-chat-btn" onClick={handleNewChat}>
          + New Chat
        </button>
        <div className="user-card">
          <FaUser size={32} className="p-1 rounded-full bg-slate-400" />
          <div className="user-detail w-2/3">
            {/* <h2 className=" font-bold">Aditya Puri</h2> */}
            <h3 className="text-sm text-center user-name-sidebar">
              {user.userName && user.userName.split("@")[0]}
            </h3>
          </div>

          {dropUp && (
            <div className="drop">
              <MdLogout size={20} color="#917EB1" />

              <button
                type="button"
                className="logout-btn font-semibold"
                onClick={handleLogout}
              >
                Sign out
              </button>
            </div>
          )}

          <BiDotsVerticalRounded
            onClick={showDropUp}
            size={22}
            color="#917EB1"
          />
        </div>
      </div>
    </header>
  );
};

export default Sidebar;
