import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FaUser } from "react-icons/fa";

const DoctorProfileCard = ({
  doctor_name,
  doctor_phone,
  doctor_exp,
  doctor_website,
  doctor_role,
  doctor_id,
  doctor_img,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleViewProfile = (doctorId) => {
    if (location.pathname === "/") {
      navigate("/signin");
      return;
    }
    navigate(`/home/doctor/${doctorId}`);
  };

  return (
    <div className="doctor-card">
      <div className="banner" />
      {doctor_img ? (
        <img
          src={doctor_img}
          className="doctor-img"
          alt="doctor-img"
          loading="lazy"
        />
      ) : (
        <FaUser className="doctor-img" />
      )}
      <div className="card-details ">
        <div className="card-detail">
          <span className="text-slate-500">Name:</span>
          <span>{doctor_name}</span>
        </div>
        <div className="card-detail">
          <span className="text-slate-500">Contact:</span>
          <span>{doctor_phone}</span>
        </div>
        <div className="card-detail">
          <span className="text-slate-500">Website:</span>

          <span>{doctor_website && doctor_website.substring(0, 21)}...</span>
        </div>
        <div className="card-detail">
          <span className="text-slate-500">Experience:</span>
          <span>
            {/* {doctor_role !== 0 && `${doctor_role.substring(0, 30)}... `}
            {doctor_role !== 0 && <br />} */}
            {doctor_exp} years -{" "}
            {doctor_role.length > 27
              ? `${doctor_role.substring(0, 28)}... `
              : doctor_role}
          </span>
        </div>
        <button
          type="button"
          className="view-profile-btn text-sm py-1 w-max"
          onClick={() => handleViewProfile(doctor_id)}
        >
          View Profile
        </button>
      </div>
    </div>
  );
};

DoctorProfileCard.propTypes = {
  doctor_name: PropTypes.string,
  doctor_phone: PropTypes.string,
  doctor_exp: PropTypes.string,
  doctor_website: PropTypes.string,
  doctor_role: PropTypes.string,
  doctor_id: PropTypes.number,
  doctor_img: PropTypes.string,
};

DoctorProfileCard.defaultProps = {
  doctor_name: "",
  doctor_phone: "0",
  doctor_exp: "",
  doctor_website: "",
  doctor_role: "",
  doctor_id: 0,
  doctor_img: "",
};

export default DoctorProfileCard;
