import React from "react";
import { useNavigate } from "react-router-dom";

import { IoArrowBackCircleOutline } from "react-icons/io5";

const BackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (window.location.pathname.includes("chat")) {
      navigate("/home");
      return;
    }
    navigate(-1);
  };

  return (
    <div className="back-btn-wrapper">
      <IoArrowBackCircleOutline
        className="cursor-pointer"
        onClick={handleGoBack}
        color="#917EB1"
        size={28}
      />
    </div>
  );
};

export default BackButton;
