/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";
import { FaMapMarkerAlt, FaUser } from "react-icons/fa";

const TableList = ({
  doctor_name,
  doctor_state,
  doctor_id,
  doctor_img,
  doctor_phone,
  doctor_website,
  doctor_role,
  doctor_exp,
}) => {
  const navigate = useNavigate();

  // const [imagesLoaded, setImagesLoaded] = useState(false);
  // const handleImagesLoad = () => {
  //   setImagesLoaded(true);
  // };

  return (
    <div
      className="doctor-detail text-[15px] cursor-pointer"
      onClick={() => navigate(`/home/doctor/${doctor_id}`)}
    >
      <img
        src={doctor_img}
        className="md:h-14 h-12 object-cover rounded-full"
        alt={doctor_name}
        loading="lazy"
      />

      <div className="table-col">
        <h2 className="font-medium">{doctor_name}</h2>
        <span className="flex gap-1 items-center">
          <FaMapMarkerAlt size={12} />
          <h2 className="text-[13px] text-light">{doctor_state}</h2>
        </span>
      </div>
      <div className="table-col">
        <h2>{doctor_role}</h2>
        <span className="flex gap-1 items-center">
          <h2 className="text-[13px] text-light">Exp. {doctor_exp}</h2>
        </span>
      </div>
      <div className="table-col">
        <h2 className="text-[13px] text-light">{doctor_website}</h2>
      </div>
      <div className="table-col">
        <h2 className="text-[13px] text-light">{doctor_phone}</h2>
      </div>
    </div>
  );
};

TableList.propTypes = {
  doctor_name: PropTypes.string,
  doctor_state: PropTypes.string,
  doctor_id: PropTypes.number,
  doctor_img: PropTypes.string,
  doctor_phone: PropTypes.string,
  doctor_website: PropTypes.string,
  doctor_role: PropTypes.string,
  doctor_exp: PropTypes.number,
};

TableList.defaultProps = {
  doctor_name: "",
  doctor_state: "",
  doctor_id: "",
  doctor_img: "",
  doctor_phone: "",
  doctor_website: "",
  doctor_role: "",
  doctor_exp: 0,
};

export default TableList;
