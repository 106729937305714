import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaUser, FaUsers, FaWindowClose } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-hot-toast";
import PropTypes from "prop-types";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdLogout, MdSpaceDashboard } from "react-icons/md";
import RecentChats from "./RecentChats";
import { clearChat } from "../features/chatSlice";
import { logo } from "../assets";
import { logOut, storeSession } from "../features/authSlice";

const SidebarMobile = ({ handleToggle }) => {
  const [dropUp, setDropUp] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const store = useSelector((state) => state);

  const { chatSlice, authSlice } = store;

  const { recentChat } = chatSlice;
  const { user } = authSlice;

  const showDropUp = () => {
    setDropUp(!dropUp);
  };

  const createSession = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.token}`,
    };

    const session_name = "New Chat";

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/v1/session?session_name=${session_name}`,
        {},
        { headers }
      );

      if (response.statusText === "OK") {
        const { session_id } = response.data.data;
        dispatch(storeSession(session_id));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          toast.error(`${error.response.data.errorData.message}`);
        } else if (error.response.status === 401) {
          toast.error(`${error.response.data.errorData.message}`);
        } else {
          toast.error(`${error.response.data.errorData.message}`);
        }
      } else if (error.request) {
        toast.error(`No Response received`);
      } else {
        toast.error(`No Response received`);
      }
    }
  };
  const handleNewChat = () => {
    navigate("/home");
    dispatch(clearChat());
    createSession();
  };

  const handleLogout = () => {
    dispatch(logOut());
    toast.success("Signed-out Successfully!!!");
    navigate("/home");
  };

  return (
    <header className="sidebar-mobile-wrapper">
      <div className="sidebar-mobile">
        <FaWindowClose
          size={22}
          color="#917EB1"
          className="close-sidebar"
          onClick={handleToggle}
        />
        <div className="sidebar-top ">
          <div className="sidebar-upper flxCol s-p !pb-4 border-b-[1px] border-light">
            <div
              className="flex items-center gap-2 mb-2 cursor-pointer"
              onClick={() => navigate("/home")}
            >
              <img
                src={logo}
                className="md:w-10 w-6 mx-1 object-contain"
                alt="logo"
              />
              <h2 className="font-bold md:text-2xl text-xl text-primary">
                mediq.expert
              </h2>
            </div>
            <ul>
              <li className="nav-item" onClick={handleNewChat}>
                <MdSpaceDashboard size={20} color="#917EB1" />

                <h3 className="nav-text">Dashboard</h3>
              </li>
              <li
                className="nav-item"
                onClick={() => navigate("/home/doctors")}
              >
                <FaUsers size={20} color="#917EB1" />

                <h3 className="nav-text">Doctor Directory</h3>
              </li>
              {/* <li
                className="nav-item"
                onClick={() => navigate("/home/appointment")}
              >
                <img
                  className="icons sidebar-icons"
                  src={calendar}
                  alt="icon"
                />

                <h3 className="nav-text">Appointments</h3>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="flex-grow overflow-auto ">
          {recentChat.length > 0 && (
            <div className="sidebar-mid flxCol s-p ">
              <span className="flex justify-between items-center cursor-pointer">
                <h2 className=" font-semibold text-primary ">Recent Chats</h2>
                {/* <img className="icons sidebar-icons" src={arrowDown} alt="icon" /> */}
              </span>
              <RecentChats />
            </div>
          )}
        </div>

        <div className="sidebar-lower !pt-4 flxCol s-p border-t-[1px] border-light">
          <button
            type="button"
            className="new-chat-btn select-none"
            onClick={handleNewChat}
          >
            + New Chat
          </button>
          <div className="user-card">
            <FaUser size={32} className="p-1 rounded-full bg-slate-400" />

            <div className="user-detail w-2/3">
              {/* <h2 className=" font-bold">Aditya Puri</h2> */}
              <h3 className="text-sm text-center user-name-sidebar">
                {user.userName && user.userName.split("@")[0]}
              </h3>
            </div>

            {dropUp && (
              <div className="drop ">
                <MdLogout size={20} color="#917EB1" />
                <button
                  type="button"
                  className="logout-btn font-semibold select-none"
                  onClick={handleLogout}
                >
                  Sign out
                </button>
              </div>
            )}
            <BiDotsVerticalRounded
              onClick={showDropUp}
              size={22}
              color="#917EB1"
            />
          </div>
        </div>
      </div>
    </header>
  );
};

SidebarMobile.propTypes = {
  handleToggle: PropTypes.func.isRequired,
};

export default SidebarMobile;
