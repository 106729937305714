/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { motion } from "framer-motion";
import Sidebar from "./Sidebar";
import Wrapper from "./Wrapper";
import SidebarMobile from "./SidebarMobile";

const Home = () => {
  const [toggle, setToggle] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setToggle(false);
  }, [location]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <Sidebar />
      <div className="toggler header">
        {!toggle && (
          <>
            <FaBars
              size={22}
              className="sidebar-crumbs"
              color="#917EB1"
              onClick={handleToggle}
            />
            <div
              className="head-title text-primary"
              onClick={() => navigate("/home")}
            >
              mediq.expert
            </div>
          </>
        )}
      </div>

      {toggle && (
        <motion.div
          className="z-50"
          initial={{ x: -1000 }}
          animate={{ x: 0 }}
          exit={{ x: -1000 }}
          transition={{ duration: 0.3 }}
        >
          <SidebarMobile handleToggle={handleToggle} />
        </motion.div>
      )}

      <Wrapper>
        <Outlet />
      </Wrapper>
    </>
  );
};

export default Home;
